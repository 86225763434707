.article img {
  width: 16vw;
  border-radius: 5px;
}
.article .description {
  max-width: 800px;
  gap: 1rem;
}
.article .description p {
  color: #808080;
}

@media screen and (max-width: 920px) {
  .article img {
    width: 24vw;
  }
  .article .description h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  .article .description p {
    font-size: 12px;
    line-height: 14px;
  }
}
@media screen and (max-width: 640px) {
  .article .description p {
    display: none;
  }
}


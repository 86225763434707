.Contact {
  padding: 2rem 50px;
  max-width: 1440px;
  margin: 0 auto;
}
.Contact h1 {
  margin-bottom: 48px;
}
.Contact .map {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Contact .map img {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 64px;
  border-radius: 5px;
}
.Contact .text-area {
  gap: 64px;
}
.Contact .text-area .descriptions {
  gap: 2rem;
}
.Contact .text-area .descriptions .description .title {
  color: #406D70;
  margin-bottom: 8px;
}
.Contact .text-area .rendez-vous p {
  width: max-content;
}
.Contact .text-area .rendez-vous img {
  width: 30px;
  margin: 0;
}
.Contact .text-area .slogan span {
  color: #406D70;
}

@media screen and (max-width: 715px) {
  .Contact {
    padding: 2rem 20px;
  }
  .Contact h1 {
    margin-bottom: 24px;
  }
  .Contact .text-area {
    flex-direction: column;
  }
}


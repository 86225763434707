.article{
    img{
        width: 16vw;
        border-radius: 5px;
    }

    .description{
        max-width: 800px;
        gap: 1rem;
        p{
            color: #808080;
        }
    }
}

@media screen and (max-width:920px) {
    .article{
        img{
            width: 24vw;
        }
        .description{
            h2{
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
            }
            p{
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

@media screen and (max-width:640px) {
    .article{

        .description{
            p{
                display: none;
            }
        }
    }
}
.Actualites .image-area {
  width: 100vw;
  margin: -2rem 0 0 -50px;
  height: 290px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Actualites .text-area {
  margin: 0 auto;
  max-width: 800px;
}
.Actualites .text-area h1 {
  margin-top: 4rem;
}
.Actualites .text-area p {
  text-align: justify;
  white-space: pre-line;
}
.Actualites .text-area .fileLink {
  width: 100%;
  padding: 1rem;
  background-color: #F9F9FB;
  border-radius: 3px;
  margin-top: 3rem;
  color: #404040;
  box-sizing: border-box;
}
.Actualites .text-area .fileLink:hover {
  background-color: #ededf1;
}

@media screen and (max-width: 715px) {
  .Actualites {
    padding: 2rem 20px;
  }
  .Actualites h1 {
    font-size: 24px;
  }
}


.IdentiteValeurs {
  padding: 2rem 50px;
  max-width: 1440px;
  margin: 0 auto;
}
.IdentiteValeurs .header {
  margin-bottom: 4rem;
}
.IdentiteValeurs .header .switch-btn {
  margin-left: auto;
}
.IdentiteValeurs .header .switch-btn p:hover {
  cursor: pointer;
}
.IdentiteValeurs .header .switch-btn p {
  color: #A1A1A1;
}
.IdentiteValeurs .header .switch-btn p.active {
  color: #000000;
}
.IdentiteValeurs .body {
  gap: 4rem;
}
.IdentiteValeurs .body img {
  max-width: 470px;
}
.IdentiteValeurs .body .text-area {
  gap: 4rem;
  align-items: flex-start;
}
.IdentiteValeurs .body img {
  border-radius: 7px;
}

@media screen and (max-width: 1180px) {
  .IdentiteValeurs .identite {
    flex-direction: column;
  }
  .IdentiteValeurs .identite img {
    margin: 0 auto;
    width: 100%;
    max-width: 570px;
    height: 200px;
    object-fit: cover;
  }
}
@media screen and (max-width: 870px) {
  .IdentiteValeurs .header {
    align-items: flex-start;
    margin-bottom: 24px;
  }
  .IdentiteValeurs .header .switch-btn {
    flex-direction: column;
  }
  .IdentiteValeurs .valeurs .text-area {
    flex-direction: column;
  }
  .IdentiteValeurs .valeurs .text-area img {
    order: 1;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 570px;
    height: 200px;
    object-fit: cover;
  }
  .IdentiteValeurs .valeurs .text-area p {
    order: 2;
  }
}
@media screen and (max-width: 715px) {
  .IdentiteValeurs {
    padding: 2rem 20px;
  }
}


.SavoirFaire {
  padding: 2rem 50px;
}
.SavoirFaire h1 {
  margin-bottom: 3rem;
}
.SavoirFaire .savoirFaireItemContainer {
  margin-bottom: 8rem;
  gap: 4rem;
}
.SavoirFaire .resume {
  gap: 6rem;
}
.SavoirFaire .resume .p-xl-bold {
  font-size: 48px;
  line-height: 58.09px;
}
.SavoirFaire .resume .p-xl-bold span {
  color: #406D70;
}
.SavoirFaire .resume .arguments {
  gap: 1.5rem;
  margin-left: auto;
}
.SavoirFaire .resume .arguments .column {
  gap: 8px;
}
.SavoirFaire .resume .arguments .column .description {
  color: #5F5F5F;
}
.SavoirFaire .resume .arguments .line {
  width: 100%;
  height: 1px;
  background-color: #5F5F5F;
}

@media screen and (max-width: 835px) {
  .SavoirFaire .resume {
    flex-direction: column;
  }
  .SavoirFaire .resume p {
    order: 2;
  }
  .SavoirFaire .resume .arguments {
    order: 1;
  }
}
@media screen and (max-width: 715px) {
  .SavoirFaire {
    padding: 2rem 20px;
  }
  .SavoirFaire .savoirFaireItemContainer {
    margin-bottom: 5rem;
  }
  .SavoirFaire .resume .slogan {
    font-size: 36px;
    line-height: 43.09px;
  }
  .SavoirFaire .resume .arguments p {
    font-size: 18px;
  }
}


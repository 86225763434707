.Actualites {
  padding: 2rem 50px;
}
.Actualites h1 {
  margin-bottom: 48px;
}

@media screen and (max-width: 715px) {
  .Actualites {
    padding: 2rem 20px;
  }
  .Actualites .image-area {
    margin: -2rem 0 0 -20px !important;
    height: 200px;
  }
  .Actualites h1 {
    font-size: 24px;
  }
}


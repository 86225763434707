@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
    margin: 0;
}

h1,h2,h3,p,a,li,input{
    margin: 0;
    font-family: "Inter";
    letter-spacing: 0em;
    text-align: left;
}

.h1-l{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.h1-m{
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
}

.h2-m-semi-bold{
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
}

.h2-m-medium{
    font-size: 26px;
    font-weight: 500;
    line-height: 31px;
}

.h2-s-semi-bold{
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
}

.h2-s-medium{
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
}

.p-xl-bold{
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
}

.p-xl-semi-bold{
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
}

.p-xl-medium{
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
}

.p-xl-regular{
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
}

.p-l-bold{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.p-l-semi-bold{
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.p-l-medium{
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

.p-l-regular{
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.p-m-bold{
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
}

.p-m-semi-bold{
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
}

.p-m-medium{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.p-m-regular{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}

.p-s-bold{
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
}

.p-s-semi-bold{
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
}

.p-s-medium{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.p-s-regular{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.p-xs-bold{
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
}

.p-xs-semi-bold{
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
}

.p-xs-medium{
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.p-xs-regular{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.p-xxs-bold{
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
}

.p-xxs-semi-bold{
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
}

.p-xxs-medium{
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
}

.p-xxs-regular{
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
}

img{
    object-fit: contain;
}

a{
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-decoration: none;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.column{
    display: flex;
    flex-direction: column;
}

.align-start{
    align-items: flex-start ;
}

.space-between{
    justify-content: space-between;
}
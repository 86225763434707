.HomePage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 50px;
  max-width: 1440px;
  margin: 0 auto;
}
.HomePage .text-area {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.HomePage .text-area .intro {
  max-width: 595px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.HomePage .text-area .intro h1 {
  display: flex;
  flex-direction: column;
  font-size: 48px;
  font-weight: 600;
  gap: 8px;
}
.HomePage .text-area .intro h1 span {
  color: #406D70;
}
.HomePage .text-area .rendez-vous {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.HomePage .text-area .raccourcis {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.HomePage .text-area .raccourcis .raccourci {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 3rem 2rem;
}
.HomePage .text-area .raccourcis .raccourci .title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.HomePage .text-area .raccourcis .raccourci p {
  text-align: center;
  width: 315px;
}
.HomePage .text-area .raccourcis .line {
  height: 87px;
  width: 2px;
  border-radius: 1px;
  background-color: #D2D2D2;
}
.HomePage .image-area {
  width: 35vw;
  border-radius: 4px;
  margin-left: auto;
}
.HomePage .mobile-image {
  display: none;
}

@media screen and (max-width: 1345px) {
  .HomePage {
    flex-direction: column;
  }
  .HomePage .text-area {
    flex-direction: row;
    align-items: center;
  }
  .HomePage .text-area .raccourcis {
    flex-direction: column;
  }
  .HomePage .text-area .raccourcis .raccourci {
    padding: 1.5rem 1rem;
  }
  .HomePage .text-area .raccourcis .line {
    height: 2px;
    width: 120px;
    border-radius: 1px;
    background-color: #D2D2D2;
  }
  .HomePage .image-area {
    margin: 0;
  }
  .HomePage .mobile-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .HomePage .desktop-image {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .HomePage .text-area {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 715px) {
  .HomePage {
    padding: 2rem 20px;
  }
}


.IdentiteValeurs{
    padding: 2rem 50px;
    max-width: 1440px;
    margin: 0 auto;

    .header{
        margin-bottom: 4rem;
        .switch-btn{
            margin-left: auto;
            p:hover{
                cursor: pointer;
            }
            p{
                color: #A1A1A1;
            }
            p.active{
                color: #000000;
            }
        }
    }
    .body{
        gap: 4rem;
        img{
            max-width: 470px;
        }
        .text-area{
            gap: 4rem;
            align-items: flex-start;
        }

        img{
            border-radius: 7px;
        }
    }

    
}

@media screen and (max-width:1180px) {
    .IdentiteValeurs{
        .identite{
            flex-direction: column;
            img{
                margin: 0 auto;
                width: 100%;
                max-width: 570px;
                height: 200px;
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width:870px) {
    .IdentiteValeurs{
        .header{
            align-items: flex-start;
            margin-bottom: 24px;
            .switch-btn{
                flex-direction: column;
            }

        }
        .valeurs{
            .text-area{
                flex-direction: column;
                img{
                    order: 1;
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 570px;
                    height: 200px;
                    object-fit: cover;
                }
                p{
                    order: 2;
                }
            }
        }
    }
    
}

@media screen and (max-width:715px){
    .IdentiteValeurs{
        padding: 2rem 20px;
    }
}
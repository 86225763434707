.HomePage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 50px;
    max-width: 1440px;
    margin: 0 auto;
    .text-area{
        display: flex;
        flex-direction: column;
        gap: 5rem;
        .intro{
            max-width: 595px;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            h1{
                display: flex;
                flex-direction: column;
                font-size: 48px;
                font-weight: 600;
                gap: 8px;
                span{
                    color: #406D70;
                }
            }
        }
        .rendez-vous{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
        .raccourcis{
            display: flex;
            flex-direction: row;
            align-items: center;
            .raccourci{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                padding: 3rem 2rem;
                .title{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    gap: 1rem;
                }
                p{
                    text-align: center;
                    width: 315px;
                }
            }
            .line{
                height: 87px;
                width: 2px;
                border-radius: 1px;
                background-color: #D2D2D2;
            }
        }
    }
    .image-area{
        width: 35vw;
        border-radius: 4px;
        margin-left: auto;
    }
    .mobile-image{
        display: none;
    }
}

@media screen and (max-width: 1345px) {
    .HomePage{
        flex-direction: column;
        .text-area{
            flex-direction: row;
            align-items: center;
            .raccourcis{
                flex-direction: column;
                .raccourci{
                    padding: 1.5rem 1rem;
                }
                .line{
                    height: 2px;
                    width: 120px;
                    border-radius: 1px;
                    background-color: #D2D2D2;
                }
            }
        }
        
        .image-area{
            margin: 0;
        }

        .mobile-image{
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
        .desktop-image{
            display: none;
        }

    }
}

@media screen and (max-width: 1000px) {
    .HomePage{
        .text-area{
            flex-direction: column !important;
        }
    }
}

@media screen and (max-width:715px){
    .HomePage{
        padding: 2rem 20px;
    }
}
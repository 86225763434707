.SavoirFaireItem{
    .savoirFaireImage{
        width: 96px;
        height: 43px;
        border-radius: 48px;
    }
    .title{
        width: 247px;
    }
    .description{
        width: 600px;
        color: #5F5F5F;
    }
    .enSavoirPlusContainer{
        margin-left: auto;
        .enSavoirPlus{
            width: 132px;
        }
        .mobile{
            display: none;
        }
    }
    .enSavoirPlusContainer:hover{
        cursor: pointer;
    }
}
.backgroundModal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    .modalSavoirFaire{
        z-index: 1000;
        padding: 2rem;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 800px;
        width: 80vw;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .headerModal{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .titleModal{
                font-size: 24px;
                font-weight: 600;
                line-height: 29px;
            }
            .close{
                width: 2rem;
                height: 2rem;
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 1.5rem;
                }
                cursor: pointer;
                &:hover{
                    background-color: #F2F2F2;
                }
            }
        }
    }
}

@media screen and (max-width:880px) {


    .SavoirFaireItem{

        .title{
            font-size: 18px;
        }

        .description{
            display: none;
        }

        .enSavoirPlusContainer{
            .mobile{
                display: block;
            }
            .desktop{
                display: none;
            }
            p{
                display: none;
            }
        }

    }
}
.SavoirFaire{
    padding: 2rem 50px;
    h1{
        margin-bottom: 3rem;
    }
    .savoirFaireItemContainer{
        margin-bottom: 8rem;
        gap: 4rem;
    }
    .resume{
        gap: 6rem;
        .p-xl-bold{
            font-size: 48px;
            line-height: 58.09px;
            span{
                color: #406D70;
            }
        }
        .arguments{
            gap: 1.5rem;
            margin-left: auto;
            .column{
                gap: 8px;
                .description{
                    color: #5F5F5F;
                }
            }
            .line{
                width: 100%;
                height: 1px;
                background-color: #5F5F5F;
            }
        }
    }
}

@media screen and (max-width: 835px) {
    .SavoirFaire{
        .resume{
            flex-direction: column;
            p{
                order: 2;
            }
            .arguments{
                order: 1;
            }
        }
    }
}

@media screen and (max-width:715px){
    .SavoirFaire{
        padding: 2rem 20px;
        .savoirFaireItemContainer{
            margin-bottom: 5rem;
        }
        .resume{
            .slogan{
                font-size: 36px;
                line-height: 43.09px;
            }
            .arguments{
                p{
                    font-size: 18px;
                }
            }
        }
    }
}